
import http from '@/services/api';
import { mountUrlWP } from '@/utils/mountURL'

export function sendGetContentWP(url: any): Promise<any> {
  url = mountUrlWP(url);
  return http.get(url)
    .then( (response) => {
      return response;
    })
    .catch( (error) => {
      console.log(error);
      return error;
    });
}

export function ajustContentImgLink(content: string): string{
  content = content.replaceAll('http://duunoayo6b0nybm.laranjadaterra.es.gov.br', 'https://aps.laranjadaterra.es.gov.br')
  content = content.replaceAll('&#8220;', 'asd')
  return content;
}

export function getPostUrlImage(post: any){
  const urlThumb = _getThumbnailUrlImage(post);
  if( !urlThumb ){
    return _getUrlDefaultThumbnail();
  }
  return urlThumb;
}

export function getBoletimUrlImage(post: any){
  const urlThumb = _getThumbnaiBoletimlUrlImage(post);
  if( !urlThumb ){
    return _getUrlDefaultThumbnail();
  }
  return urlThumb;
}

function _getThumbnailUrlImage(post: any): any {
  if(post.thumbnail_images == undefined ||  post.thumbnail_images.length == 0 || post.thumbnail_images.medium_large == undefined){
    return false;
  }
  let url = post.thumbnail_images.medium_large.url;
  let arrUrl = url.split('/');
  arrUrl = arrUrl.splice(3, 8)
  url = arrUrl.join('/')
  return 'https://aps.laranjadaterra.es.gov.br/'+url
}

function _getThumbnaiBoletimlUrlImage(post: any): any {
  if(post.thumbnail_images == undefined ||  post.thumbnail_images.length == 0 || post.thumbnail_images.full == undefined){
    return false;
  }
  let url = post.thumbnail_images.full.url;
  let arrUrl = url.split('/');
  arrUrl = arrUrl.splice(3, 8)
  url = arrUrl.join('/')
  return 'https://aps.laranjadaterra.es.gov.br/'+url
}

function _getUrlDefaultThumbnail(): any{
  const images = require.context('@/assets/img/', false, /\.jpg$/)
  return images('./' + 'thumbPadrao' + ".jpg");
}

export function mountQueryPagination(objectFilter: any){
  let str  = '';
  let i = 1;
  for(const key in objectFilter){
    str = str + key + '=' + objectFilter[key];
    if( i >= 1 && i < Object.keys(objectFilter).length){
      str = str + '&'
    }
    i = i + 1;
  }
  return str;
}

export default {
  sendGetContentWP,
  ajustContentImgLink,
  getPostUrlImage,
  getBoletimUrlImage
};