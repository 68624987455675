import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import content from '@/services/content';
import ShowPath from '@/components/showPath/showPath.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    ShowPath
  }
})
export default class ContentWP extends Vue {
  // pagina, boletim or noticia
  @Prop({required: true}) type!: string;
  @Prop({required: true}) slug!: string;

  title = '';
  content = '';
  path: any = [];
  isLoading = false;

  mounted() {
    this.sendGetContent(this.mountUrl(this.slug))
  }

  mountUrl(slug: string): string{
    if(this.type == 'pagina'){
      return `/get_page/?slug=${slug}`
    }
    else if(this.type == 'boletim'){
      return `/get_post/?post_type=boletim&slug=${slug}`
    }
    else {
      return `/get_post/?slug=${slug}`
    }
  }

  sendGetContent(url: string): void{
    this.title = '';
    this.content = '';
    this.isLoading = true;
    content.sendGetContentWP(url)
      .then( (response) => {
        this.setContent(response.data);
      })
      .finally( () => this.isLoading = false );
  }

  setContent(data: any): void{
    if(this.type == 'pagina'){
      this.content = content.ajustContentImgLink(data.page.content);
      this.title = data.page.title;
      this.path = [
        { title: 'Início', to: '/'},
        { title: this.title},
      ];
    }
    else if(this.type == 'boletim'){
      this.content = content.ajustContentImgLink(data.post.content);
      this.title = data.post.title;
      this.path = [
        { title: 'Início', to: '/'},
        { title: 'Boletins', to: '/boletins'},
        { title: this.title},
      ];
    }
    else {
      this.content = content.ajustContentImgLink(data.post.content);
      this.title = data.post.title;
      this.path = [
        { title: 'Início', to: '/'},
        { title: 'Notícias', to: '/noticias'},
        { title: this.title},
      ];
    }
  }

  @Watch('slug', { 
    immediate: false, deep: false 
  })
  slugChange(newVal: string) {
    this.sendGetContent(this.mountUrl(newVal))
  }

  @Watch('type', { 
    immediate: false, deep: false 
  })
  metaChange() {
    this.sendGetContent(this.mountUrl(this.slug))
  }

}



/*
- pagina
https://aps.laranjadaterra.es.gov.br/api/core/get_page/?slug=prefeito-2

- boletim
https://aps.laranjadaterra.es.gov.br/api/core/get_post/?post_type=boletim&slug=boletim-17-10

- noticia
https://aps.laranjadaterra.es.gov.br/api/core/get_post/?slug=3-concurso-do-cafe-conilon 
*/